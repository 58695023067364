import gql from "graphql-tag";

const GET_HOST = gql`
  query getHost($product_id: Int!) {
    getHost(product_id: $product_id) {
      first_name
      last_name
      description
      media {
        media {
          id
          path
          filename
        }
      }
    }
  }
`;

const CREATE_OR_UPDATE_HOST = gql`
  mutation createOrUpdateHost($data: HostInput!) {
    createOrUpdateHost(data: $data) {
      id
      first_name
      last_name
    }
  }
`;

export {
  GET_HOST,
  CREATE_OR_UPDATE_HOST
}
