<template>
  <DangerZone />
  <AdminZone v-role="['admin']" />
</template>
<script>
import DangerZone from "@/components/views/Listing/Settings/DangerZone";
import AdminZone from "@/components/views/Listing/Settings/AdminZone";

export default {
  name:"ListingSettings",
  components: { AdminZone, DangerZone }
}
</script>
