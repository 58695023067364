<template>
  <SectionWrapper
    id="danger-zone"
    title="Snooze listing"
    description="You can always regret this decision and relist your place."
    :show-save-button="false"
    :is-valid-section="true">
    <div class="border-t">
      <div class="flex flex-col sm:flex-row  gap-4 sm:justify-between items-center py-6">
        <div>
          <h3 class="text-gray-800 font-medium">Snooze listing</h3>
          <p class="text-gray-500 text-sm max-w-md">
            This will unlist your place on Owayy. <br>
            Existing bookings on future dates will still have to be completed.
          </p>
        </div>
        <div v-role="['host']">
          <a href="mailto:host@owayy.com" class="btn btn-sm btn-white flex-shrink-0 bg-gray-50 hover:bg-gray-100">
            Contact us
          </a>
        </div>
        <div v-role="['admin']">
          <button class="btn btn-sm flex-shrink-0 btn-white bg-gray-50 hover:bg-gray-100" @click="unPublish">
            Snooze listing
          </button>
        </div>
      </div>
      <div v-role="['admin']" class="mt-4">
        <div class="flex flex-col sm:flex-row gap-4 sm:justify-between items-center">
          <div>
            <h3 class="text-gray-800 font-medium">Delete this listing</h3>
            <p class="text-gray-500 text-sm max-w-md">
              Be very careful, this cannot be undone. All existing bookings on future dates,
              will still have to be completed.
            </p>
          </div>
          <div>
            <button class="btn btn-sm flex-shrink-0 btn-white bg-gray-50 hover:bg-gray-100" @click="toggleDeleteListingModal">
              Delete listing
            </button>
          </div>
        </div>
        <Modal :isOpen="isDeleteModalOpen" @toggleModal="toggleDeleteListingModal">
          <template v-slot:title>
            Delete listing
          </template>
          <template v-slot:content>
            <p class="text-sm text-gray-500">
              Are you sure you want to delete this listing?

              All of your data will be permanently removed. This action cannot be undone.
            </p>
          </template>
          <template v-slot:buttonName>
            Delete
          </template>
          <span></span>
        </Modal>
      </div>
    </div>
  </SectionWrapper>
</template>

<script>
import { ref } from "vue";
import { useMutation } from "@vue/apollo-composable";
import { useRoute } from "vue-router";
import SectionWrapper from "@/components/UI/SectionWrappers/AppSectionWrapper";
import Modal from "@/components/UI/Popups/Modal/AppModal";
import { SET_PRODUCT_UNPUBLISHE } from "@/graphql/products/mutations";
import { notify } from "@kyvg/vue3-notification";

export default {
  name: "DangerZone",
  components: { Modal, SectionWrapper },
  setup() {
    const route = useRoute();
    const isDeleteModalOpen = ref(false);
    const toggleDeleteListingModal = () => {
      isDeleteModalOpen.value = !isDeleteModalOpen.value;
    };

    const { mutate: unPublish, onDone: onSnooze } = useMutation(SET_PRODUCT_UNPUBLISHE,{
      variables: {
        id: Number(route.params.id)
      }
    })

    onSnooze(() => {
      notify({
        title: 'Listing snoozed',
        type: 'warn'
      })
    });

    return {
      isDeleteModalOpen,
      toggleDeleteListingModal,
      unPublish
    }
  }
};
</script>

<style scoped>

</style>
