<template>
  <div class="relative">
    <SectionWrapper
      id="admin-zone"
      title="Admin zone"
      class="border-highlight-500"
      description="Settings that only admins have access to"
      :is-valid-section="true"
      :loading="updateAdminZoneLoading"
      @saveSection="submit">
      <div class="absolute top-0 right-0">
        <div class="top-0 right-0 flex justify-end">
          <div class="rounded-bl-lg inline-flex bg-highlight-500 px-2 py-1">
            <span class="text-sm text-white font-medium ">Admin only</span>
          </div>
        </div>
      </div>
      <div class="divide-y">
        <div class="border-t py-4">
          <p  class="block text-sm font-medium text-gray-700">Approve</p>
          <p  class="mt-2 text-sm text-gray-500">Approve or decline product.</p>
          <div class="flex mt-2">
            <button
              :disabled="loadingDecline || loadingApprove"
              class="btn btn-green"
              @click="approve">
              <LoadingSpinner v-if="loadingApprove" class="w-5 h-5" outer-class="text-white" />
              <span v-else>Approve</span>
            </button>
            <button
              :disabled="loadingDecline || loadingApprove"
              class="btn btn-red ml-2"
              @click="decline">
              <LoadingSpinner v-if="loadingDecline" class="w-5 h-5" outer-class="text-white" />
              <span v-else>Decline</span>
            </button>
          </div>
        </div>
        <div class="border-t py-4">
          <div class="mt-1">
            <InputNumberNight
              id="commision"
              v-model:value="commission"
              label="Commision"
              input-class="w-14"
              input-suffix="%" />
          </div>
          <p id="commision-description" class="mt-2 text-sm text-gray-500">This should not be changed.</p>
        </div>
        <div class="py-4">
          <div class="max-w-sm">
            <label for="listing-slug" class="block text-sm font-medium text-gray-700">Slug</label>
            <div class="mt-1">
              <input id="listing-slug" v-model="slug" type="text" name="unit-title" class="focus:ring-highlight-500 focus:border-highlight-500
          block w-full sm:text-sm border-gray-300 rounded-md" placeholder="title" aria-describedby="email-description" />
            </div>
            <p id="unit-title-description" class="mt-2 text-sm text-gray-500">Make sure to avoid special characters like øæå.</p>
          </div>
        </div>
        <div class="py-4">
          <div class="max-w-xs">
            <Combobox v-if="!loading" v-model="selectedUser" as="div">
              <ComboboxLabel class="block text-sm font-medium text-gray-700">Host</ComboboxLabel>
              <div class="relative mt-1">
                <ComboboxInput class="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-highlight-500 focus:outline-none focus:ring-1 focus:ring-highlight-500 sm:text-sm"
                               :display-value="(person) => (`${person.first_name} ${person.last_name}`)"
                               @change="query = $event.target.value" />
                <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                  <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                </ComboboxButton>

                <ComboboxOptions v-if="filteredPeople.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  <ComboboxOption v-for="person in filteredPeople" :key="person.email" :value="person" as="template" v-slot="{ active, selected }">
                    <li :class="['group relative cursor-default select-none py-2 pl-3 pr-9',
                                 active ? 'bg-highlight-600 text-white' : 'text-gray-900']">
                      <div class="flex">
                        <span :class="['truncate', selected && 'font-semibold']">
                          {{ `${person.first_name}  ${person.last_name}` }}
                        </span>
                        <span :class="['ml-2 truncate text-gray-500 group-hover:text-gray-100',
                                       active ? 'text-highlight-200' : 'text-gray-500']">
                          {{ person.email }}
                        </span>
                      </div>
                      <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4',
                                                     active ? 'text-white' : 'text-highlight-600']">
                        <CheckIcon class="h-5 w-5" aria-hidden="true" />
                      </span>
                    </li>
                  </ComboboxOption>
                </ComboboxOptions>
              </div>
            </Combobox>
          </div>
          <div class="bg-yellow-50 border-l-4 border-yellow-500 p-2 mt-4">
            <div class="flex">
              <div class="flex-shrink-0">
                <ExclamationIcon class="h-6 w-6 text-yellow-400" aria-hidden="true" />
              </div>
              <div class="ml-1 flex items-top gap-2 divide-x divide-yellow-300">
                <div class="pl-2 text-sm text-yellow-700">
                  <p>
                    Do not change user unless you know what you're doing. <br />
                    Changing the user will give the new user access to all history of the old user
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="py-4 border-t">
        <div>
          <div class="max-w-full w-44">
            <SelectorUI v-if="!loading" v-model:value="selectedLabel" :items="labels" label="Listing Label" />
          </div>
        </div>
      </div>
      <!--      <UserInfoUI v-model:description="hostDescription" v-model:name="hostName" v-model:media="hostMedia" />-->
    </SectionWrapper>
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue';
import { useQuery, useMutation } from "@vue/apollo-composable";
import { useStore } from "vuex";
import * as yup from "yup";
import { useField, useForm, useIsFormDirty, useIsFormValid } from "vee-validate";
import { useRoute } from "vue-router";
import SelectorUI from "@/components/UI/Inputs/SelectorUI";
import SectionWrapper from "@/components/UI/SectionWrappers/AppSectionWrapper";
import LoadingSpinner from "@/components/UI/Indication/LoadingSpinner";
// import UserInfoUI from "@/components/UI/User/UserInfoUI";
import InputNumberNight from "@/components/UI/Inputs/InputNumberNight";
import { GET_SETTINGS_INFO } from "@/graphql/products/queries";
import { CREATE_OR_UPDATE_HOST } from "@/graphql/products/host/host";
import {
  SET_PRODUCT_DECLINED,
  SET_PRODUCT_VERIFIED,
  UPDATE_PRODUCT
} from "@/graphql/products/mutations";
import { notify } from "@kyvg/vue3-notification";

export default {
  name: "AdminZone",
  components: {
    InputNumberNight,
    SectionWrapper,
    SelectorUI,
    LoadingSpinner
    // UserInfoUI
  },
  setup(_,{ emit }) {
    const route = useRoute();
    const store = useStore();

    const isCreateProductMode = !route.params.id;

    const settings = computed(() => store.state.product.product.settings);

    const slug = ref(store.state.product.product.generalInfo.slug);

    const { result, loading, onResult } = useQuery(GET_SETTINGS_INFO, {
      product_id: Number(route.params.id)
    }, () =>  ({
      enabled: store.getters["auth/isAdmin"],
      fetchPolicy: 'cache-and-network'
    }));


    const users = computed(() => result.value ? result.value?.getVerifiedUsers : []);


    const labels = computed(() => result.value ? [ ...result.value?.getLabels.map(label => ({
      title: label.translation.title,
      id: label.id
    })), {
      title: 'None',
      id: null
    }] : [] )

    const selectedUser = ref({
      first_name: '',
      last_name: ''
    });
    const selectedLabel = ref(null);

    const hostDescription = ref("");
    const hostName = ref("");
    const hostMedia = ref({
      media: null,
      media_id: null
    });

    onResult((data) => {
      try {
        const { data: { getVerifiedUsers: users, getHost: host } } = data;
        selectedUser.value = users.find(user => user.id === settings.value.userId);
        selectedLabel.value = labels.value.find(label => label.id === settings.value.labelId);
        hostDescription.value = host.description;
        hostName.value = host.first_name;
        hostMedia.value = {
          media: host.media ? `${process.env.VUE_APP_ASSETS_URL}/${host.media.media.path}` : null,
          media_id: host.media ? host.media.media.id : null
        }
      } catch (err) {
      }
    })

    const query = ref('')

    const filteredPeople = computed(() =>
      query.value === ''
        ? users.value
        : users.value.filter((person) => `${person.first_name} ${person.last_name}`
          .toLowerCase()
          .includes(query.value.toLowerCase()))
    );

    const schema = yup.object({
      commission: yup.string().required()
    });

    const { errors, handleSubmit, values, submitCount } = useForm({
      initialValues: {
        commission: settings.value.commission
      },
      validationSchema: schema,
      validateOnMount: true
    })

    const { value: commission } = useField('commission');

    const isSectionDirty = useIsFormDirty();
    const isSectionValid = useIsFormValid();

    const isValidSection = computed(() => isSectionDirty.value && isSectionValid.value);

    const isUnsaved = computed(() => isSectionDirty.value && submitCount.value === 0);

    watch(isSectionValid, value => emit('checkIsValid', 'adminZone', value));

    watch(isUnsaved, value => {
      emit('checkIsUnsaved', {
        title: 'Admin zone'
      }, value)
    });

    emit('updateData', 'adminZone', values);

    watch(values, value => {
      emit('updateData', 'adminZone', value);
    });

    const { mutate: updateHost, loading: updateAdminZoneLoading } = useMutation(CREATE_OR_UPDATE_HOST);

    const { mutate: updateProduct } = useMutation(UPDATE_PRODUCT, () => ({
      variables: {
        id: +route.params.id,
        data: {
          urlms: [
            {
              locale: "en",
              url: `/listings/${slug.value}`,
              title: store.state.product.product.generalInfo.title
            }
          ]
        }
      }
    }));

    const submit = handleSubmit( async ({
      commission
    }) => {
      updateHost({
        data: {
          description: hostDescription.value,
          first_name: hostName.value,
          last_name: '',
          product_id: Number(route.params.id),
          media: hostMedia.value.media ? {
            file: hostMedia.value.file,
            position: 1,
            is_primary: true
          } : undefined
        }
      })
      if(isCreateProductMode.value) {

      } else {
        const preparedData = {
          commission: +commission,
          user_id: selectedUser.value.id,
          label_id: selectedLabel.value.id
        };
        await store.dispatch('product/updateProduct', { id: +route.params.id, data: preparedData });
        await updateProduct();
      }
    });

    const { mutate: approve, loading: loadingApprove, onDone: onApprove } = useMutation(SET_PRODUCT_VERIFIED, {
      variables:{
        id: Number(route.params.id)
      }
    });

    onApprove(() => {
      notify({
        title: 'Product approved',
        type: 'success'
      })
    });

    const { mutate: decline, loading: loadingDecline, onDone: onDecline } = useMutation(SET_PRODUCT_DECLINED, {
      variables:{
        id: Number(route.params.id)
      }
    });

    onDecline(() => {
      notify({
        title: 'Product declined',
        type: 'warn'
      })
    });

    return {
      query,
      filteredPeople,
      users,
      loading,
      errors,
      selectedUser,
      commission,
      submit,
      selectedLabel,
      labels,
      hostName,
      hostDescription,
      hostMedia,
      isValidSection,
      updateAdminZoneLoading,
      approve,
      decline,
      loadingApprove,
      loadingDecline,
      slug
    }
  }
};
</script>

<style scoped></style>
