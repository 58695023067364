<template>
  <div>
    <label
      :for="id"
      class="block text-sm text-gray-700 flex items-center gap-1">
      {{ label }}
      <slot name="label-explanation"></slot>
    </label>
    <div class="mt-1">
      <div class="relative border rounded-md inline-flex cursor-text overflow-hidden
      focus-within:ring-1 focus-within:ring-highlight-500 focus-within:border-highlight-500">
        <input
          :id="id"
          type="number"
          :min="min"
          :max="max"
          :name="id"
          :value="value"
          :disabled="disabled"
          class="no-arrows block text-center px-1 py-2 sm:text-md border-none
              focus:outline-none focus:ring-0"
          :class="inputClass"
          @input="$emit('update:value', $event.target.value)" />
        <label :for="id" class="cursor-text block bg-gray-50 px-3 right-0 flex items-center">
          <span class="text-gray-600 text-sm"> {{ inputSuffix }} </span>
        </label>
      </div>
      <span class="input-error"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "InputNumberNight",
  props: {
    id: {
      type: String,
      required: true
    },
    label: {
      type: String
    },
    inputSuffix: {
      type: String,
      required: 'Nights'
    },
    min: {
      type: [Number, String],
      default: 0
    },
    max: {
      type: [Number, String],
      default: 50
    },
    inputClass: {
      type: String,
      default: 'w-12'
    },
    value: {
      type: [Number, String]
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:value']
};
</script>

<style scoped>

</style>
